<template>
  <div>
    <template v-if="starCount">
      <i
        class="fas fa-star text-warning"
        v-for="i in starCount"
        v-bind:key="'filled-' + i"
      ></i>
    </template>
    <template v-if="5 - starCount">
      <i
        class="far fa-star"
        style="color: #cdcdcd"
        v-for="j in 5 - starCount"
        v-bind:key="'empty-' + j"
      ></i>
    </template>
  </div>
</template>
<script>
export default {
  name: "ProductStar",
  props: {
    starCount: {
      default: 0,
    },
  },
};
</script>